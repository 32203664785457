import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Logo } from "../../../assets/icons/qlikpayLogo.svg";
import Stack from "@mui/material/Stack";
import { HearderContainer } from "./index.style";

const Header = () => {
    return (
        <HearderContainer>
            <Stack
                direction='row'
                sx={{
                    height: "53px",
                    backgroundColor: "background.paper",
                    padding: "13px 24px 14px 24px",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 28,
                        fontWeight: 700,
                    }}
                >
                    <Logo />
                </Box>
            </Stack>
        </HearderContainer>
    );
};

export default Header;
