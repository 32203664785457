/* eslint-disable no-unused-vars */
import { AxiosResponse } from "axios";
import { CORE_ACTIONS } from "core/store/app/actions";
import { getAlertType } from "shared/helpers";
import {
    AccountEmail,
    BusinessValues,
    SessionKeys,
} from "core/constants";
import {
    clearLocalStorage,
    clearSessionStorage,
    clearSessionStorageKeyName,
    getFromSession,
    setSessionItem,
} from "../../../shared/helpers/auth.helper";
import { AppDispatch, history } from "../../../store/store";
import { Auth } from "../utilites/auth";
import {
    fetchUser,
    getConstants,
    getCountries,
    getTermAndCondition,
    loginOtpRequest,
    loginRequest,
    stakeHolderRequest,
} from "./api";
import { ApplicationStatusMessage } from "shared/utilities/applicationStatus";
import { getRFICoporateDocumentsRequest } from "features/RFI/store/api";

export enum AUTH_ACTIONS {
    LOGIN_REQUEST = "LOGIN_REQUEST",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILURE = "LOGIN_FAILURE",
    LOGIN_OTP_REQUEST = "LOGIN_OTP_REQUEST",
    LOGIN_OTP_SUCCESS = "LOGIN_OTP_SUCCESS",
    LOGIN_OTP_FAILURE = "LOGIN_OTP_FAILURE",
    SIGNUP_REQUEST = "SIGNUP_REQUEST",
    SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
    SIGNUP_FAILURE = "SIGNUP_FAILURE",
    LOGOUT_REQUEST = "LOGOUT_REQUEST",
    SESSION_EXPIRED = "SESSION_EXPIRED",
    APPLICATION_DATA_SUCCESS = "APPLICATION_DATA_SUCCESS",
    APPLICATION_DATA_FAIL = "APPLICATION_DATA_FAIL",
    GET_COUNTRIES_DATA_REQUEST = "GET_COUNTRIES_DATA_REQUEST",
    GET_COUNTRIES_DATA_SUCCESS = "GET_COUNTRIES_DATA_SUCCESS",
    ADD_USER_DETAILS_REQUEST = "ADD_USER_DETAILS_REQUEST",
    ADD_USER_DETAILS_SUCCESS = "ADD_USER_DETAILS_SUCCESS",
    ADD_USER_DETAILS_FAILURE = "ADD_USER_DETAILS_FAILURE",
    ADD_COMPANY_DETAILS_REQUEST = "ADD_COMPANY_DETAILS_REQUEST",
    ADD_COMPANY_DETAILS_SUCCESS = "ADD_COMPANY_DETAILS_SUCCESS",
    ADD_COMPANY_DETAILS_FAILURE = "ADD_COMPANY_DETAILS_FAILURE",
    ADD_STAKEHOLDER_DETAILS_SUCCESS = "ADD_STAKEHOLDER_DETAILS_SUCCESS",
    RESET_FORM = "RESET_FORM",
    ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST",
    ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS",
    ADD_IMAGE_FAILURE = "ADD_IMAGE_FAILURE",
    ADD_CORPORATE_CONSTANTS_DATA_SUCCESS = "ADD_CORPORATE_CONSTANTS_DATA_SUCCESS",
}

export function loginOtp(payload: any) {
    return async function (dispatch: AppDispatch) {
        dispatch({ type: AUTH_ACTIONS.LOGIN_OTP_REQUEST });
        try {
            const data = { ...payload, email: "" };
            data.email = payload.email;
            const response: AxiosResponse = await loginOtpRequest(
                data
            );
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: "OTP has sent to" + " " + data.email,
                    severity: getAlertType(response.data.code),
                    alertTitle: response.data.statusText,
                },
            });
        } catch (e) {
            dispatch({ type: AUTH_ACTIONS.LOGIN_OTP_FAILURE });
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: e.response.data.error,
                    severity: "error",
                    alertTitle: e.response.data.message,
                },
            });
        }
    };
}

export function login(payload: any) {
    return async function (dispatch: AppDispatch) {
        dispatch({ type: AUTH_ACTIONS.LOGIN_REQUEST });
        const updatedPayload = { ...payload, type: "register" };
        try {
            const response: AxiosResponse = await loginRequest(
                updatedPayload
            );
            const {
                status,
                data: { data, success },
            } = response;
            if (status === 200) {
                if (success && !data) {
                    setSessionItem(
                        AccountEmail.isLoggedIn,
                        AccountEmail.isLoggedIn
                    );
                    dispatch({
                        type: AUTH_ACTIONS.LOGIN_OTP_SUCCESS,
                        payload: payload.email,
                    });
                    setSessionItem(
                        AccountEmail.userEmail,
                        payload.email
                    );
                    history.replace(
                        "/onboarding/getting-to-know-you"
                    );
                }
                setSessionItem(AccountEmail.userEmail, payload.email);
                if (success && data.id && data.customer_hash_id) {
                    setSessionItem(AccountEmail.loader, "loader");
                    const res: AxiosResponse = await fetchUser(
                        data.id,
                        data.customer_hash_id,
                        data.token
                    );
                    setSessionItem(
                        SessionKeys.customerType,
                        res.data.data.customerType.toLowerCase()
                    );
                    clearSessionStorageKeyName(AccountEmail.loader);
                    if (res?.data.success && res.data.data) {
                        if (
                            // res.data.data.complianceStatus ? res.data.data.complianceStatus.toLowerCase():null ===

                            res?.data.data.complianceStatus.toLowerCase() ===
                            ApplicationStatusMessage.reject
                        ) {
                            setSessionItem(
                                AccountEmail.isLoggedIn,
                                AccountEmail.isLoggedIn
                            );
                            dispatch({
                                type: AUTH_ACTIONS.LOGIN_OTP_SUCCESS,
                                payload: payload.email,
                            });
                            history.replace(
                                "/onboarding/getting-to-know-you"
                            );
                        } else if (
                            res.data.data.complianceStatus.toLowerCase() ===
                            ApplicationStatusMessage.rfiRequested
                        ) {
                            history.replace("/rfi/coporate");
                        }
                        dispatch({
                            type: AUTH_ACTIONS.APPLICATION_DATA_SUCCESS,
                            payload: {
                                complianceStatus:
                                    res.data.data.complianceStatus,
                                status: res.data.data.status,
                            },
                        });
                        if (
                            (!res.data.data.complianceStatus &&
                                res?.data?.data?.status.toLowerCase() !=
                                    ApplicationStatusMessage.pending) ||
                            res?.data?.data?.complianceStatus.toLowerCase() ===
                                ApplicationStatusMessage.reject
                        ) {
                            history.replace(
                                "/onboarding/getting-to-know-you"
                            );
                        }
                    } else {
                        dispatch({
                            type: AUTH_ACTIONS.APPLICATION_DATA_FAIL,
                            payload: {
                                complianceStatus: "",
                                status: "",
                            },
                        });
                    }
                }
            } else {
                history.replace("/onboarding");
            }
        } catch (e) {
            dispatch({ type: AUTH_ACTIONS.LOGIN_FAILURE });
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: e.response.data.message,
                    severity: getAlertType(400),
                    alertTitle: e.response.data.error,
                },
            });
        }
    };
}

export function logout() {
    return (dispatch: AppDispatch) => {
        clearSessionStorage();
        clearLocalStorage();
        dispatch({ type: AUTH_ACTIONS.LOGIN_REQUEST });
    };
}

export function gettingToKnowYouAction(data: any) {
    return async function (dispatch: AppDispatch) {
        // dispatch({ type: AUTH_ACTIONS.ADD_USER_DETAILS_REQUEST });
        try {
            dispatch({
                type: AUTH_ACTIONS.ADD_USER_DETAILS_SUCCESS,
                payload: data,
            });
            history.push("/onboarding/getting-to-know-your-company");
        } catch (e) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: e.response.data.message,
                    severity: getAlertType(400),
                    alertTitle: e.response.data.error,
                },
            });
            dispatch({ type: AUTH_ACTIONS.ADD_USER_DETAILS_FAILURE });
        }
    };
}

export function gettingToKnowCompanyAction(data: any) {
    return async function (dispatch: AppDispatch) {
        dispatch({ type: AUTH_ACTIONS.ADD_COMPANY_DETAILS_REQUEST });
        try {
            dispatch({
                type: AUTH_ACTIONS.ADD_COMPANY_DETAILS_SUCCESS,
                payload: data,
            });
            history.push("/onboarding/stakeholder");
        } catch (e) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: e.response.data.message,
                    severity: getAlertType(400),
                    alertTitle: e.response.data.error,
                },
            });
            dispatch({
                type: AUTH_ACTIONS.ADD_COMPANY_DETAILS_FAILURE,
            });
        }
    };
}
export function uploadImage(formData: any) {
    return async function (dispatch: AppDispatch) {
        dispatch({ type: AUTH_ACTIONS.ADD_IMAGE_REQUEST });
        try {
            dispatch({
                type: AUTH_ACTIONS.ADD_IMAGE_SUCCESS,
                payload: { document: formData },
            });
        } catch (e) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: e.response.data.message,
                    severity: getAlertType(400),
                    alertTitle: e.response.data.error,
                },
            });
            dispatch({ type: AUTH_ACTIONS.ADD_IMAGE_SUCCESS });
        }
    };
}
export function getCountriesAction() {
    return async (dispatch: AppDispatch) => {
        dispatch({ type: AUTH_ACTIONS.GET_COUNTRIES_DATA_REQUEST });
        try {
            const response: AxiosResponse = await getCountries();
            const { data } = response.data;
            dispatch({
                type: AUTH_ACTIONS.GET_COUNTRIES_DATA_SUCCESS,
                payload: {
                    countries: data,
                },
            });
        } catch (error) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: error.response.data.message,
                    severity: getAlertType(400),
                    alertTitle: error.response.data.error,
                },
            });
        }
    };
}
export function setStakeHolder(body: any, stakeData: any) {
    return async (dispatch: AppDispatch) => {
        dispatch({
            type: AUTH_ACTIONS.ADD_STAKEHOLDER_DETAILS_SUCCESS,
            payload: stakeData.stakeholders,
        });
        try {
            const response: AxiosResponse = await stakeHolderRequest(
                body
            );
            const { data } = response;
            if (data.success && data.data.redirectUrl === "") {
                clearSessionStorage();
                history.replace("/final-page");
                dispatch({ type: AUTH_ACTIONS.RESET_FORM });
            }
            if (data.success && data.data.redirectUrl !== "") {
                clearSessionStorage();
                window.location.href = data.data.redirectUrl;
            }
        } catch (error) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: error.response.data.error,
                    severity: getAlertType(400),
                    alertTitle: Auth.error,
                },
            });
        }
    };
}
export function gettingTermAndCondition() {
    return async (dispatch: AppDispatch) => {
        try {
            const response: AxiosResponse =
                await getTermAndCondition();
            const { data } = response;
            if (data.success) {
                return data;
            }
        } catch (error) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: error.response?.data?.message,
                    severity: getAlertType(400),
                    alertTitle: error.response.data.error,
                },
            });
        }
    };
}

export function sessionExpired() {
    return (dispatch: AppDispatch) => {
        dispatch({ type: AUTH_ACTIONS.SESSION_EXPIRED });
    };
}

export function getCorporateConstant() {
    return async (dispatch: AppDispatch) => {
        try {
            const response: AxiosResponse = await getConstants();
            const { data } = response.data;
            dispatch({
                type: AUTH_ACTIONS.ADD_CORPORATE_CONSTANTS_DATA_SUCCESS,
                payload: {
                    corporateConstants: data,
                },
            });
        } catch (error) {
            dispatch({
                type: CORE_ACTIONS.ENABLE_MODAL,
                payload: {
                    message: error.response?.data?.message,
                    severity: getAlertType(400),
                    alertTitle: error?.response?.data?.error,
                },
            });
        }
    };
}
