/* eslint-disable no-unused-vars */
export enum ApplicationStatusMessage {
  blocked = "blocked",
  suspended = "suspended",
  clear = "clear",
  completed = "completed",
  notClear = "not clear",
  initiated = "initiated",
  inProgress = "in_progress",
  actionRequired = "action-Required",
  rfiResponded = "rfi-responded",
  rfiRequested = "rfi_requested",
  error = "error",
  expired = "expired",
  pending = "pending",
  reject = "reject",
}

export enum BusinessTypes {
  associations = "ASSOCIATION",
  regulatedTrust = "Regulated Trust",
  unregulatedTrust = "Unregulated Trust",
  publicCompany = "PUBLIC_COMPANY",
  trust = "TRUST",
}
export enum SelectPositionStakeHolder {
  ubo = "Ultimate Beneficial Owners (UBO)",
}
export enum PositionTypes{
  ubo = "UBO",
  director = "DIRECTOR",
  shareholder="SHAREHOLDER"
}

export enum KycTypes{
  eKyc = "E_KYC",
  manualKyc = "MANUAL_KYC",
}
