import { createTheme } from "@mui/material";
import { IState } from "../../shared/utilities/interfaces";
import store from "../../store/store";
const Theme = () => {
  const appState: IState = store.getState();
  const { themeMode } = appState.app;
  return createTheme({
    typography: {
      fontFamily: ["Mulish", "sans-serif"].join(","),
    },
    palette: {
      ...(themeMode === "light"
        ? {
            primary: {
              main: "#EC3934",
            },
            secondary: {
              main: "#F7F9FD",
              dark: "#1A1A1A",
            },
            error: {
              main: "#EC3934",
            },
            success: {
              main: "#27AE60",
              contrastText: "#219653",
            },
            warning: {
              main: "#F2994A",
          },
           titleColor: {
              textColor:'#FFFFFF'
            },
            qlikpayPalette: {
              darkGray: "#A9A9A9",
              gray1: "#828282",
              gray2: "#D3D3D3",
              gray3: "#E0E0E0",
              gray4: "#F2F2F2",
              gray5: "#E5E5E5",
              gray6: "#333333",
            },
            background: {
              paper: "#FFFFFF",
            },
          }
        : {
            primary: {
              main: "#5D5D5D",
            },
          }),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
					@font-face {
						font-family: 'Mulish';
						font-style: normal;
						font-weight: 400;
						font-display: swap;
					}
					@font-face {
						font-family: 'Mulish';
						font-style: normal;
						font-weight: 700;
						font-display: swap;
					}
					@font-face {
						font-family: 'Mulish';
						font-style: italic;
						font-weight: 400;
						font-display: swap;
					}
					@font-face {
						font-family: 'Mulish';
						font-style: italic;
						font-weight: 700;
						font-display: swap;
					}
					@font-face {
						font-family: 'Mulish';
						font-style: normal;
						font-weight: 300;
						font-display: swap;
					}
				`,
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "#A9A9A9",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            fontWeight: 700,
            "&.loading": {},
          },
          contained: {
            '&:hover': {
              boxShadow: 'none', // Remove the box shadow on hover
              backgroundColor:'#EC3934 !important'
            },
          },
          outlined: {
            '&:hover': {
              boxShadow: 'none', // Remove the box shadow on hover
            },
          }
        },
      },
      MuiFormHelperText:
      {
        styleOverrides: {
          root: {
            fontWeight: 700,
           }
         }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          nativeInput: {
            borderBottom: "none",
          },
          icon: {
            color: "#A9A9A9",
          },

          select: {
            color: "#1A1A1A",
            "&:focus": {
              backgroundColor: "white",
            },
            "&:error": {
              color: "red",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "16px",
            lineHeight: "19px",
            fontWeight: "500",
            padding: "7px 16px",
            borderRadius: "8px",
            color: "#1A1A1A",
            "&:focus": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#A9A9A9 !important",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.em": {
              color: "#5D5D5D",
            },
          },
          h1: {
            fontSize: "6rem",
          },
          h2: {
            fontSize: "3.75rem",
          },
          h3: {
            fontSize: "3rem",
          },
          h4: {
            fontSize: "2rem",
          },
          h5: {
            fontSize: "1.5rem",
          },
          h6: {
            fontSize: "1.25rem",
          },
          subtitle1: {
            fontSize: "1rem",
          },
          subtitle2: {
            fontSize: "0.875rem",
          },
          body1: {
            fontSize: "0.875rem",
          },
          body2: {
            fontSize: "0.75rem",
          },
        },
      },
    },
  });
};
export default Theme;
